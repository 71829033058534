import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Img8 from '../images/new/blog/post/image-8.jpeg'
import Img9 from '../images/new/blog/post/image-9.jpeg'

import { 
  section,
  container,
  content,
  column,
  columns
} from '../components/styles/Base.styles'


const BlogItem = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Matemática de Singapura" />
    <NavBar />
    {/* <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div> */}
    <div css={[section, content]} style={{ paddingBottom: 0, marginTop: 112 }}>
      <div css={container}>
        <h1>Matemática de Singapura</h1>
        <p>Um dos conceitos da matemática de Singapura é proporcionar aos estudantes uma forma de absorver a modalidade a partir de três pontos importantes e fundamentais (concreto, pictórico e abstrato) para a assimilação das quatro operações (soma, subtração, divisão e multiplicação).</p>
        <p>Diferente do que muitos de nós aprendeu, de trás para frente, absorvendo o abstrato (número simbólico) sem ter a noção do concreto , a matemática de Singapura, adotada pela Kingdom School, traz o inverso, passando primeiramente pelo concreto(usando vários recursos que proporcionam vivência concreta), depois passa para o pictórico(representação gráfica), até chegar a abstração matemática.</p>
        <p>Essa trilha torna o caminho das crianças significativo, pois vem com uma proposta de solução de problemas que aflora a curiosidade dos estudantes e torna a assimilação dos conceitos matemáticos e sua conectividade de forma eficaz. Afinal, a matemática está em todo lugar e o seu domínio traz grandes benefícios aos estudantes desde à pré-escola. </p>
        <p>As docentes do Fundamental e Pré-escola da Kingdom School (Kids 5) tiveram, de forma intensiva, um treinamento para esse método durante a Semana Pedagógica, que foi realizado pela equipe da professora Janete Arce, especializada na matemática de Singapura, e pelo professor Danny Calderón, que veio diretamente do Peru para compartilhar os seus ensinamentos, despertando nas participantes entusiasmo para a aplicabilidade do método. </p>
        <div css={columns}>
          <div css={column}>
            <img src={Img8} alt='' style={{ width: '90%' }} />
          </div>
          <div css={column}>
            <img src={Img9} alt='' style={{ width: '90%' }} />
          </div>
        </div>
				<div style={{ paddingBottom: 112 }} />
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default BlogItem
